import React, { useState } from "react";
import Navbar from './components/Navbar';
import Home from './components/Home';
import AllPosts from './components/Blog/AllPosts';
import Section from './components/Objects/Section';

function App() {

  const [blogShowing, setBlogShowing] = useState<boolean>(false);

  const getPageContent = () => {
    if (blogShowing){
      return (
        <Section id="blog" internal={<AllPosts />} />
      );
    }
    else{
      return (<Home switchToBlog={()=>setBlogShowing(true)} switchFromBlog={()=>setBlogShowing(false)} />);
    }
  };

  return (
    <main className="max-w-full min-h-screen text-body bg-backgrnd body-font">
        <Navbar switchToBlog={()=>setBlogShowing(true)} switchFromBlog={()=>setBlogShowing(false)} />
        <div className="container mx-auto pb-20 w-full">
          {getPageContent()}
        </div>
      </main>
  );
}

export default App;
