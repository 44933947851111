import React from "react";
import Welcome from './Sections/Welcome';
import About from './Sections/About';
import Contact from './Sections/Contact';
import Section from './Objects/Section';

interface Props{
  switchToBlog(): void;
  switchFromBlog(): void;
}

export default function Home(props:Props) {
  return (
    <div>
      <Section id="welcome" internal={<Welcome switchToBlog={props.switchToBlog} switchFromBlog={props.switchFromBlog} />} />
      <div className="flex md:flex-row flex-col items-center">
        <Section id="about" internal={<About />} />
        <Section id="contact" internal={<Contact />} />
      </div>
    </div>
  );
}