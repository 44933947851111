import React, { useEffect, useState } from "react";
import sanityClient from "../../client";
import Title from '../Objects/Title';
import OnePost from './OnePost';

interface categ{
  title:string;
}

export default function AllPosts() {
  const defaultCateg:categ = {title:"All"}
  const [allPostsData, setAllPosts] = useState([{title:null, slug:{current:"test"}, mainImage:{asset:{url:""}}}]);
  var [currentPost, setCurrentPost] = useState<null|string>(null);
  const [allCategories, setAllCategories] = useState<Array<categ>>([defaultCateg]);
  var [currentCategory, setCurrentCategory] = useState<string>("All");

  useEffect(() => {
      initFetchPosts();

      sanityClient
      .fetch(
        `*[_type == "category"]{
          title
        }`
      )
      .then((data) => {
        var arr = data;
        arr.push({title:"All"});
        setAllCategories(arr);
      }).catch(console.error);
  },[]);

  const initFetchPosts = () => {
    sanityClient
    .fetch(
      `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
            _id,
            url
          }
        }
      }`
    )
    .then((data) => setAllPosts(data))
    .catch(console.error);
  }

  const getCategoryPosts = (category:string) => {
    setCurrentPost(null);
    setCurrentCategory(category);
    if (category === "All"){
      initFetchPosts();
    }
    else{
      sanityClient
      .fetch(
        `*[_type == "post" && references(*[_type == "category" && title==$category]._id)]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            }
          }
        }`, 
        { category }
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);
    }
  }

  function mapData(){
    if (allPostsData){
      return(
        allPostsData.map((post, index) => (
          <a key={index} onClick={()=>setCurrentPost(post.slug.current)} href="#selected">
            <span
              className="block h-64 relative rounded-3xl shadow leading-snug bg-white green-400"
              key={index}
            >
              <img
                className="w-full h-full rounded-2xl object-cover absolute"
                src={post.mainImage.asset.url}
                alt=""
              />
              <span className="block relative h-full flex justify-end items-end pr-4 pb-4">
                <h2 className="text-gray-800 text-lg font-bold px-3 py-4 bg-hilight text-title bg-opacity-75 rounded-full">
                  {post.title}
                </h2>
              </span>
            </span>
          </a>
        ))
      )
    }
  }

  function showBlog(){
    if (currentPost == null){ return <></> }
    return(
      <section id="selected" className="pb-10 flex flex-col">
        <a href="#all" className="text-center text-backgrnd font-semibold bg-hilight rounded-full px-4 py-2 mb-4">
          View All Posts
        </a>
        <OnePost slug={currentPost} category={(cat:string)=>getCategoryPosts(cat)}/>
        <button onClick={()=>setCurrentPost(null)} className="text-center text-backgrnd font-semibold bg-hilight rounded-full px-4 py-2 mt-4">
          Hide Post
        </button>
      </section>
    )
  }

  return (
    <div className="lg:w-5/6 xl:w-2/3 w-full">
      <Title text={"Blog Posts"} centred={true} />
      <h3 className="text-lg text-gray-600 flex justify-center mb-2">
        Welcome to my blog posts page!
      </h3>
      <h3 className="text-lg text-gray-600 flex justify-center mb-2">
        Showing Category: <div className="text-title font-semibold whitespace-pre"> {currentCategory}</div>
      </h3>
      {allCategories !== null ? <div className="flex flex-wrap text-center gap-4 mb-12">{allCategories.map((cat, i) => (
        <a key={i} 
          className="flex-auto px-4 py-1 bg-lowlight rounded-full"
          href="#all"
          onClick={()=>getCategoryPosts(cat.title)}
        >{cat.title}</a>
      ))}</div>:<></>}
      {showBlog()}
      <section id="all" className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {mapData()}
      </section>
    </div>
  );
}