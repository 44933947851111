import React, { useEffect, useState } from "react";
import sanityClient from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
import { AnnotationIcon } from "@heroicons/react/outline";
import { CopyBlock } from "react-code-blocks";
import cbTheme from '../Objects/CodeBlockTheme';
import Title from '../Objects/Title';


const BlockRenderer = (props) => {
  const {style = 'normal'} = props.node
  
  if (props.children[0] === ""){
    return <div className="text-justify font-light whitespace-pre-wrap"> </div>
  }

  if (style === "h1"){ return <Title text={props.children}/> }
  if (style === "h2"){ return <h2 className="title-font text-2xl font-semibold text-title mt-8 mb-1">{props.children}</h2> }
  if (style === "h3"){ return <h3 className="title-font text-xl font-semibold text-title mt-8 mb-1">{props.children}</h3> }
  if (style === "h4"){ return <h4 className="title-font text-lg font-semibold text-title mt-8 mb-1">{props.children}</h4> }

  if (style === 'blockquote') { return (
    <div className="my-4 mx-8 p-2 flex flex-row items-center bg-lowlight rounded-3xl">
      <AnnotationIcon className="h-4 flex-none pr-2"/>
      <p className="flex-grow italic font-serif whitespace-wrap">{props.children}</p>
    </div>);
  }

  if (props.children[0].props){
    if (props.children[0].props.node.mark.href){
      return ( <div className="text-hilight underline">{props.children}</div> );
    }
    if (props.children[0].props.node.mark === "code"){
      return ( <div className="bg-lowlight my-2 px-1 rounded text-hilight">{props.children}</div> );
    }
    console.log(props.children[0].props.node.mark)
  }

  return <div className="text-justify break-words font-light tracking-wide whitespace-wrap">{BlockContent.defaultSerializers.types.block(props)}</div>
}

const CodeRenderer = (props) => {
  return (
    <div className="my-2">
      <CopyBlock text={props.node.code} language={props.node.language}
        showLineNumbers={false} theme={cbTheme} />
    </div>
  );
}

const ImageRenderer = (props) => {
  return (
    <div className="flex justify-center my-2"> 
      {BlockContent.defaultSerializers.types.image(props)}
    </div>);
}

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}



export default function OnePost(props) {
  const [postData, setPostData] = useState(null);
  const slug = props.slug;
  const setCategory = props.category;

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          body,
          publishedAt,
          "name": author->name,
          "authorImage": author->image,
          "categories": categories[]->title
        }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  function getBody(){
    if (!postData.body) return (<></>);
    return(
      <div className="bg-backgrnd lg:px-16 px-4 py-12 w-full rounded-b-2xl flex flex-col">
        <BlockContent
          blocks={postData.body}
          serializers={{types: {block: BlockRenderer, code:CodeRenderer, image:ImageRenderer}}}
          projectId={sanityClient.config().projectId}
          dataset={sanityClient.config().dataset}
        />
        <div className="px-16 py-2 mt-12 bg-hilight rounded-3xl">
          <h4 className="w-full text-center text-lowlight font-semibold text-lg pb-2">
            View similar posts by category:
          </h4>
          <div className="flex flex-wrap text-center gap-4">
            {postData.categories.map((cat, i) => (
              <a key={i} 
                className="flex-auto px-4 py-1 bg-lowlight rounded-full"
                href="#all"
                onClick={()=>setCategory(cat)}
              >{cat}</a>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!postData) return <div>Loading...</div>;

  return (
  <div className="container w-full bg-lowlight py-12 lg:px-12 sm:px-8 px-4 rounded-3xl">
      <div className="relative">
        <div className="absolute h-full w-full flex items-end justify-center p-8">
          <div className="bg-hilight bg-opacity-60 rounded-2xl p-8 pb-2">
            <h2 className="cursive text-center text-backgrnd text-3xl lg:text-6xl mb-4">
              {postData.title}
            </h2>
            <div className="flex flex-row justify-between items-center">
              <div className="flex justify-center text-backgrnd">
                <img
                  src={urlFor(postData.authorImage).url()}
                  className="w-10 h-10 mr-2 rounded-full text-center"
                  alt="Author"
                />
                <h4 className="cursive flex items-center pl-2 text-2xl">
                  {postData.name}
                </h4>
              </div>
              <h4 className="w-24 text-right cursive text-backgrnd text-2xl">
                {new Intl.DateTimeFormat('en-GB').format(new Date(postData.publishedAt))}
              </h4>
            </div>
            <h4 className="mt-4 w-full text-center cursive text-backgrnd text-2xl">
              Categories:
            </h4>
            <div className="flex flex-wrap text-center gap-4 mt-1 cursive">
              {postData.categories.map((cat, i) => (
                <a key={i} 
                   className="flex-auto px-2 bg-lowlight rounded-full font-light"
                   href="#all"
                   onClick={()=>setCategory(cat)}
                   >{cat}</a>
              ))}
            </div>
          </div>
        </div>
        <img
          className={postData.body ? "w-full object-cover rounded-t-2xl" : "w-full object-cover rounded-2xl"}
          src={urlFor(postData.mainImage).url()}
          alt=""
          style={{ height: "400px" }}
        />
      </div>
      {getBody()}
    </div>
  );
}