let cbTheme = {
  lineNumberColor: `#042fcc`,
  lineNumberBgColor: `#1f2937`,
  backgroundColor: `#1f2937`,
  textColor: `#d1d5db`,
  substringColor: `#668fb3`,
  keywordColor: `#5eb1fa`,
  attributeColor: `#046dcc`,
  selectorTagColor: `#046dcc`,
  docTagColor: `#668fb3`,
  nameColor: `#5eb1fa`,
  builtInColor: `#046dcc`,
  literalColor: `#5eb1fa`,
  bulletColor: `#046dcc`,
  codeColor: `#046dcc`,
  additionColor: `#668fb3`,
  regexpColor: `#668fb3`,
  symbolColor: `#668fb3`,
  variableColor: `#d1d5db`,
  templateVariableColor: `#5eb1fa`,
  linkColor: `#5eb1fa`,
  selectorAttributeColor: `#5eb1fa`,
  selectorPseudoColor: `#5eb1fa`,
  typeColor: `#046dcc`,
  stringColor: `#668fb3`,
  selectorIdColor: `#046dcc`,
  selectorClassColor: `#046dcc`,
  quoteColor: `#668fb3`,
  templateTagColor: `#5eb1fa`,
  deletionColor: `#5eb1fa`,
  titleColor: `#046dcc`,
  sectionColor: `#d1d5db`,
  commentColor: `#042fcc`,
  metaKeywordColor: `#046dcc`,
  metaColor: `#046dcc`,
  functionColor: `#046dcc`,
  numberColor: `#046dcc`
}

export default cbTheme;