import React from "react";

interface Props{
  text:string|JSX.Element;
  centred?:Boolean;
}

export default function Title(props:Props) {
  if (props.centred){
    return (
      <h1 className="title-font sm:text-4xl text-3xl font-medium text-title text-center mb-4">
        {props.text}
      </h1>
    );
  }
  return (
    <h1 className="title-font sm:text-4xl text-3xl font-medium text-title mb-4">
      {props.text}
    </h1>
  );
}