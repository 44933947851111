import React from "react";

interface Props{
  id:string;
  internal:JSX.Element;
}

export default function Section(props:Props) {
  return (
    <section id={props.id} className="flex flex-col container mx-auto px-10 py-20 items-center justify-center">
      {props.internal}
    </section>
  );
}