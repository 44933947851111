import { FingerPrintIcon } from "@heroicons/react/solid";
import React from "react";
import Title from '../Objects/Title';

export default function About() {
  return (
    <div className="text-center">
      <FingerPrintIcon className="w-1/2 inline-block mb-4 text-hilight"/>
      <Title text={"About HackerByName"} centred={true}/>
      <p className="leading-relaxed">
        Ben Hacker - <b className="inline font-semibold text-hilight">Hacker by name, hacker by nature</b> - I'm a Computer Science Student in the UK.
      </p>
      <p className="leading-relaxed">
        This blog documents a few thoughts and findings 
        from the various projects I hack together. 
        There may even be some how-to guides, 
        if I'm feeling particularly proud of my work!
      </p>
    </div>
  );
}