import React from "react";
import { CodeIcon } from "@heroicons/react/solid";
import PrimaryButton from '../Objects/Buttons/Primary';
import SecondaryButton from '../Objects/Buttons/Secondary';
import Title from '../Objects/Title';

interface Props{
  switchToBlog(): void;
  switchFromBlog(): void;
}

export default function Welcome(props:Props) {
  
  const pageTitle = <div>A huge "Hello!" from HackerByName. <br className="hidden lg:inline-block" /> Welcome to The Blog. </div>

  return (
    <div className="flex md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center">
        <Title text={pageTitle} />
        <div className="flex justify-center">
          <PrimaryButton buttonText="To The Blog" onClick={props.switchToBlog} href="/#" />
          <SecondaryButton buttonText="More About Me" href="/#about" />
        </div>
      </div>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 text-hilight">
        <CodeIcon />
      </div>
    </div>
  );
}