import React from "react";

interface Props{
  buttonText:string;
  href?:string;
  onClick?():void;
}

export default function PrimaryButton(props:Props) {
  const classNameToUse = "transform-gpu hover:scale-105 transition duration-200 ease-in-out inline-flex text-backgrnd bg-hilight border-0 py-2 px-6 focus:outline-none hover:bg-body hover:text-backgrnd rounded text-lg";
  
  if (props.onClick){
    return (
      <a
        href={props.href ? props.href : "#"}
        onClick={props.onClick}
        className={classNameToUse}>
        {props.buttonText}
      </a>
    );
  }

  else if (props.href){
    return (
      <a
        href={props.href}
        className={classNameToUse}>
        {props.buttonText}
      </a>
    );
  }

  else{
    return (
      <a
        href={window.location.pathname}
        className={classNameToUse}>
        {props.buttonText}
      </a>
    );
  }
}