import { ArrowRightIcon } from "@heroicons/react/solid";
import React from "react";

interface Props{
  switchToBlog(): void;
  switchFromBlog(): void;
}

export default function Navbar(props:Props) {
  return (
    <header className="bg-backgrnd pb-1 text-hilight font-semibold sm:sticky top-0 z-10 rounded-b-3xl">
      <div className="bg-lowlight rounded-b-3xl">
      <div className="container mx-auto flex flex-wrap p-5 flex-col sm:flex-row items-center">
        <a href="/#welcome" className="title-font font-medium text-white mb-4 sm:mb-0 ml-3 text-xl" onClick={props.switchFromBlog}>
          HackerByName
        </a>
        <nav className="sm:mr-auto sm:ml-4 sm:py-1 sm:pl-4 sm:border-l sm:border-gray-700	flex flex-wrap items-center text-base justify-center">
          <a href="/#" className="mr-5 hover:text-white" onClick={props.switchToBlog}>
            Blog
          </a>
          <a href="/#about" className="mr-5 hover:text-white" onClick={props.switchFromBlog}>
            About
          </a>
        </nav>
        <a
          href="/#contact" onClick={props.switchFromBlog}
          className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 sm:mt-0">
          Contact Me
          <ArrowRightIcon className="w-4 h-4 ml-1" />
        </a>
      </div>
      </div>
    </header>
  );
}