import React, { useState } from "react";
import PrimaryButton from '../Objects/Buttons/Primary';
import Title from '../Objects/Title';

export default function Contact() {
  const [nameField, setNameField] = useState("");
  const [emailField, setEmailField] = useState("");
  const [messageField, setMessageField] = useState("");
  const [messageValid, setMessageValid] = useState(true);
  const [emailValid, setEmailValid] = useState(false);

  function messageIsValid() {
    if (nameField === "") { return false; }
    if (emailField === "") { return false; }
    if (messageField === "") { return false; }
    if (!emailValid) { return false; }
    return true;
  }

  function updateMessageValidity(){
    if (messageIsValid()) setMessageValid(true);
    else setMessageValid(false);
  }

  function sendMessage() {
    if (messageIsValid()){
      console.log({nameField, emailField, messageField});
    }
    else{
      setMessageValid(false);
      console.log("Invalid")
    }
  }

  function onNameChange(e:React.ChangeEvent<HTMLInputElement>) {
    setNameField(e.target.value);
    updateMessageValidity();
  }

  function onEmailChange(e:React.ChangeEvent<HTMLInputElement>) {
    setEmailField(e.target.value)
    if (!/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(e.target.value)) { 
      /* email provided is invalid */ 
      setEmailValid(false);
    }
    else { setEmailValid(true); }

    updateMessageValidity();
  }

  function onMessageChange(e:React.ChangeEvent<HTMLTextAreaElement>) {
    setMessageField(e.target.value);
    updateMessageValidity();
  }

  var submitButtonStyle = () => {
    if (messageValid){ return(<PrimaryButton buttonText="Send Message" onClick={()=>sendMessage()} href="#contact" />) }
    else{ 
      return(
        <a onClick={()=>sendMessage()}
           href="#contact"
           className="inline-flex text-midlight bg-lowlight border-0 py-2 px-6 rounded text-lg"
        >Send Message</a>
      ) 
    }
  }

  return (
    <div
      className="flex flex-col">
      <Title text="Contact Me" />
      <p className="leading-relaxed mb-5">
        If you have any questions or suggestions relating to the content of the blog, please do get in touch via this form. I'll aim to respond to the email provided.
      </p>
      <div className="relative mb-4">
        <label htmlFor="name" className="leading-7 text-sm text-hilight">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
					onChange={(e)=>onNameChange(e)}
					value={nameField}
          className="w-full bg-lowlight rounded border border-body focus:border-hilight focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="relative mb-4">
        <label htmlFor="email" className="leading-7 text-sm text-hilight">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
					onChange={(e)=>onEmailChange(e)}
					value={emailField}
          className="w-full bg-lowlight rounded border border-body focus:border-hilight focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="relative mb-4">
        <label
          htmlFor="message"
          className="leading-7 text-sm text-hilight">
          Message
        </label>
        <textarea
          id="message"
          name="message"
					onChange={(e)=>onMessageChange(e)}
					value={messageField}
          className="w-full bg-lowlight rounded border border-body focus:border-hilight focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
        />
      </div>
      {submitButtonStyle()}
    </div>
  );
}